import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import modules from './modules'
import getters from './getters'

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules,
  getters,
});

export default store
