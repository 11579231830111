import {
    Button,
    Icon,
    Swipe,
    SwipeItem,
    Search,
    Cell,
    CellGroup,
    Field,
    Stepper,
    Popup,
    Picker,
    Calendar,
    Toast,
    Area,
    ShareSheet,
    ActionSheet,
    Dialog,
    Row,
    Col,
    Tabbar,
    TabbarItem,
    List,
} from "vant"
import '@vant/touch-emulator';
export default {
    install: function (app) {
        app.use(Button)
        app.use(Icon)
        app.use(Swipe)
        app.use(SwipeItem)
        app.use(Search)
        app.use(Cell)
        app.use(CellGroup)
        app.use(Field)
        app.use(Stepper)
        app.use(Popup)
        app.use(Picker)
        app.use(Calendar)
        app.use(Toast)
        app.use(Area)
        app.use(ShareSheet)
        app.use(ActionSheet)
        app.use(Dialog)
        app.use(Row)
        app.use(Col)
        app.use(Tabbar)
        app.use(TabbarItem)
        app.use(List)
        console.log(Button)
    }
}
