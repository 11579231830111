import Vue from 'vue';
import Router from 'vue-router'

Vue.use(Router);

const routes = [
  {
    // tab 首页
    path: '/',
    name: 'Home',
	component: () => import('../views/index'),
	meta:{
		keepAlive: true, //此组件不需要被缓存
	}
  },
  {
    // tab 商品
    path: '/products',
    name: 'products',
    component: () => import('views/products'),
	meta:{
		keepAlive: false, //此组件不需要被缓存
	}
  },
  {
    // tab 我的
    path: '/myinfo',
    name: 'myinfo',
    component: () => import('views/myinfo'),
  },
  {
    // 我的订单
    path: '/myorders',
    name: 'myorders',
    component: () => import('views/myorders'),
  },
  {
    // 订单详情
    path: '/orderdetails',
    name: 'orderdetails',
    component: () => import('views/orderdetails'),
  },
  {
    // 编辑个人信息
    path: '/myself',
    name: 'myself',
    component: () => import('views/myself'),
  },
  {
    // 我的门票
    path: '/mytickets',
    name: 'mytickets',
    component: () => import('views/mytickets'),
  },
  {
    // 我的门票i
    path: '/mytickets-detail',
    name: 'mytickets-detail',
    component: () => import('views/mytickets-detail'),
  },
  {
    // 我的收藏
    path: '/mycollections',
    name: 'mycollections',
    component: () => import('views/mycollections'),
  },
  {
    // 地址管理
    path: '/myaddress',
    name: 'myaddress',
    component: () => import('views/myaddress'),
  },
  {
    // 地址管理-编辑
    path: '/myaddressedit',
    name: 'myaddressedit',
    component: () => import('views/myaddressedit'),
  },
  {
    // 我的推广
    path: '/mypush',
    name: 'mypush',
    component: () => import('views/mypush'),
  },
  {
    // 推广名片
    path: '/myposter',
    name: 'myposter',
    component: () => import('views/myposter'),
  },
  {
    // 推广人统计
    path: '/mypushhelpers',
    name: 'mypushhelpers',
    component: () => import('views/mypushhelpers'),
  },
  {
    // 佣金明细
    path: '/bonuslist',
    name: 'bonuslist',
    component: () => import('views/bonuslist'),
  },
  {
    // 推广人订单
    path: '/mypushhelperorders',
    name: 'mypushhelperorders',
    component: () => import('views/mypushhelperorders'),
  },
  {
    // 优惠券
    path: '/mycoupons',
    name: 'mycoupons',
    component: () => import('views/mycoupons'),
  },
  {
    // 关于我们
    path: '/aboutus',
    name: 'aboutus',
    component: () => import('views/aboutus'),
  },
  {
    // tab 购物车
    path: '/cart',
    name: 'cart',
    component: () => import('views/cart'),
  },
  {
    // 商品详情
    path: '/product-detail',
    name: 'product-detail',
    component: () => import('views/product-detail'),
  },
  {
    // 环球影城
    path: '/hqyc',
    name: 'hqyc',
    component: () => import('views/hqyc'),
  },
  {
    // 商品结算
    path: '/spjs',
    name: 'spjs',
    component: () => import('views/spjs'),
  },
  {
    // 门票订单详情
    path: '/mpdd',
    name: 'mpdd',
    component: () => import('views/mpdd'),
  },
  {
    // 导游页
    path: '/daoyou',
    name: 'daoyou',
    component: () => import('views/daoyou'),
  },
  {
    // 导游页-普通模式
    path: '/daoyou-normal',
    name: 'daoyou-normal',
    component: () => import('views/daoyou-normal'),
  },
  {
    // 导游页-家庭模式
    path: '/daoyou-family',
    name: 'daoyou-family',
    component: () => import('views/daoyou-family'),
  },
  {
    // 秒杀页
    path: '/seckill',
    name: 'seckill',
    component: () => import('views/seckill'),
  },
  {
    // 直播列表页
    path: '/livelist',
    name: 'livelist',
    component: () => import('views/livelist'),
  },
  {
    // 提交订单-门票结算
    path: '/mpjs',
    name: 'mpjs',
    component: () => import('views/mpjs'),
  },
  {
    // tourCheckout
    path: '/tour-products',
    name: 'tourProducts',
    component: () => import('views/tour/products'),
  },
  {
    // tourCheckout
    path: '/tour-checkout',
    name: 'tourCheckout',
    component: () => import('views/tour/checkout'),
  },
  {
    // tourDetail
    path: '/tour-detail',
    name: 'tourDetail',
    component: () => import('views/tour/detail'),
  },
  {
    // tourSubject
    path: '/tour-subject',
    name: 'tourSubject',
    component: () => import('views/tour/subject'),
  },
  {
    // tourUniversal
    path: '/tour-universal',
    name: 'tourUniversal',
    component: () => import('views/tour/universal'),
  },
  {
    // tourUniversal
    path: '/login',
    name: 'login',
    component: () => import('views/login'),
  },
  {
    // tourUniversal
    path: '/register',
    name: 'register',
    component: () => import('views/register'),
  },
]

export default new Router({
  mode: 'history', // 后端支持可开
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && to.meta.keepAlive) {
     return savedPosition;
    }
    return { x: 0, y:0 };
   },
  // base: process.env.ROOT_ROUTER,
  routes,
});
