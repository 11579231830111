const user = {
  state: {
    user: '',
    name: '',
    user_id: '',
  },

  mutations: {
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_USERID: (state, user_id) => {
      state.user_id = user_id
    },
  },

  actions: {

  }
}

export default user
