<template>
  <div id="app">
	<keep-alive>
	  <router-view v-if="$route.meta.keepAlive">
	    <!-- 这里是会被缓存的视图组件，比如 page1 -->
	  </router-view>
	</keep-alive>
	
	<router-view v-if="!$route.meta.keepAlive">
	  <!-- 这里是不被缓存的视图组件 -->
	</router-view>
	
	<div class="btver">
		<div class="line"></div>
		小程序中涉及旅游相关等业务均由北京中远国际旅行社有限公司提供服务，时时在途为技术提供服务。
	</div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      scope: "scope.mobile",
    }
  },
  created() {
	  upsdk.pluginReady(function () {
		  upsdk.setTitleStyle({
			  // 可选，导航栏(含状态栏)背景色及透明度。16进制，前2位(8F)透明度，后六位(FFFFFF)颜色，仅对当前页有效，其余页还是申请配置的导航默认颜色
			  navBackgroundColor:'0xFFFFFFFF',
			  appletStyle: 'black', //可选，black-黑色主题，white-白色主题
			  appletTitleBarVisible: '1',// 可选，标题栏是否显示。'0'不显示，'1'显示，默认显示y
			  backBtnVisible: '1', // 可选，左侧返回按钮是否显示。'0'不显示，'1'显示，不传或空则默认显示
			});
		})
  },
  methods: {
  
  }
}
</script>

<style>
#app {
  font-family: sans-serif, Helvetica, Arial, Avenir;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/
}

.van-swipe__indicator {
  width: 15px!important;
  height: 15px!important;
}

.van-cell-group {
  padding: 14px;
}

.van-cell {
  padding-left: 0 !important;
  font-size: 30px;
  line-height: 60px;
  align-items: center;

  .van-cell__left-icon, .van-cell__right-icon {
    font-size: 30px;
  }
}
.van-calendar__selected-day {
  width: 1.42rem;
  height: 0.92rem;
}
.van-calendar__confirm {
  height: 1.18rem;
}

.btver {
	text-align: center;
	font-size: 12px;
	line-height: 34px;
	color:#999999;
	padding:20px;
}
.line {
	margin-top: 10px;
	border-top: 1px solid #999999;
	height: 10px;
}
</style>
