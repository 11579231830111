import router from '../router'
import axios from 'axios';
import QS from 'qs'

if (process.env.NODE_ENV == 'development') {
    //本地代理
    axios.defaults.baseURL = 'https://www.2gooo.cn/';
} else if (process.env.NODE_ENV == 'production') {
    //生产
    axios.defaults.baseURL = 'https://www.2gooo.cn/';
}

export const baseURL = axios.defaults.baseURL
// 请求超时时间
axios.defaults.crossDomain = true;
axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['accept'] = '*/*';
// TODO: 设置token
//axios.defaults.headers.post['x-token'] = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9zbHYuMmdvb28uY24iLCJhdWQiOiJodHRwOlwvXC9tcC53ZWl4aW4uY29tLmNuIiwiaWF0IjoxNjM1NTk5NzQzLCJuYmYiOjE2MzU1OTk3NDMsImV4cCI6MTYzODE5MTc0MywiZGF0YSI6eyJ1aWQiOjEwNDg3LCJuYW1lIjoicnQzOTk1MTU4ODU1NjQwMCJ9fQ.6C7kkzhsJ69ph1QZ5IY6Q__iWKa4Y5owV-HG9DAA_9g';
//整理数据
axios.defaults.transformRequest = function (data) {
    data = JSON.stringify(data);
    return data;
};
// http request 拦截器
axios.interceptors.request.use(
    config => {

        var loginToken = window.localStorage.getItem("userToken");
        if (loginToken) {
            config.headers["x-token"] = loginToken;
        }

        return config;
    },
    error => {
        return Promise.reject(error.response);
 });
 // http response 拦截器
 axios.interceptors.response.use(
     response => {
         if(response.data.status==401)
         {
             router.push({path: "/login"})
         }
         return response;
     },
     error => {
         return Promise.reject(error.response)   // 返回接口返回的错误信息
});

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {

    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}

/**
 * post方法
 * @param {String} api [请求的url地址]
 * @param {Object} apidata [请求时携带的参数]
 * @param {String} apimode [apimode]
 *
 */

export function post(url, data) {
    return new Promise((resolve, reject) => {
        axios.post(url, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}

