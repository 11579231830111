let prefix = '/index.php/wap/unionpay.'
let API = {
  login: `${prefix}login/login`,
  miniLogin: `${prefix}login/miniLogin`,
  relate: `${prefix}login/relate`,
  sendPhoneCode: `${prefix}login/sendPhoneCode`,

  index: `${prefix}index/index`,
  getGoodsList: `${prefix}index/goodsList`,
  liveRoom: `${prefix}index/liveRoom`,
  addCart: `${prefix}index/goods`,
  sliderMenus: `${prefix}index/guiderSliderMenu`,

  relateOrderList: `${prefix}Spread/relateOrderList`,
  spreadDetail: `${prefix}Spread/spreadDetail`,
  spreadUsersTotal: `${prefix}Spread/spreadUsersTotal`,
  spreadUsers: `${prefix}Spread/spreadUsers`,
  getPoster: `${prefix}Spread/spreadPoster`,

  cartList: `${prefix}cart/list`,
  getFamilyCart: `${prefix}cart/listByGroup`,
  getCartGoodsList: `${prefix}cart/list`,
  quickToBuy: `${prefix}cart/checkStock`,
  refeshCartTotal: `${prefix}cart/getTotal`,
  cartCheckout: `${prefix}cart/checkout`,
  cartCreatOrder: `${prefix}cart/creatOrder`,
  getCartAmount:`${prefix}cart/getAmount`,

  getTicketList: `${prefix}Tickets/tickets`,
  getTicketsDetail: `${prefix}Tickets/detail`,

  Ucenter: `${prefix}Ucenter/index`,
  goPayOrder:`${prefix}Ucenter/goPayOrder`,
  addressList: `${prefix}Ucenter/addressList`,
  addAddress: `${prefix}Ucenter/addAddress`,
  updateAddress: `${prefix}Ucenter/updateAddress`,
  setDefault: `${prefix}Ucenter/setDefault`,
  removeAddress: `${prefix}Ucenter/removeAddress`,
  myOrderList: `${prefix}Ucenter/orderList`,
  cancelOrder: `${prefix}Ucenter/cancelOrder`,
  orderDetails: `${prefix}Ucenter/orderDetails`,

  getTourGoodsList: `${prefix}tour/products`,
  tourCreateOrder: `${prefix}tour/createOrder`,
  tourOrderList: `${prefix}tour/orderList`,
  tourOrderDetail: `${prefix}tour/orderDetail`,
  tourCheckout: `${prefix}tour/checkout`,
  tourRefeshSelected: `${prefix}tour/refeshSelected`,
  tourProductDetail: `${prefix}tour/productDetail`,
  tourSubjectIndex: `${prefix}tour/SubjectIndex`,
  tourGetSubjectUrl: `${prefix}tour/getSubjectUrl`,
  goPayTicketOrder: `${prefix}Ucenter/goPayTicketOrder`,
  refundByTickets: `${prefix}tour/refundByTickets`,

  checkPay: `${prefix}cashier/checkPay`,
}

export default API
