import Vue from 'vue'
import VueCupUi from 'vue-cup-ui'
import 'vue-cup-ui/lib/vue-cup-ui.css'
import vant from 'plugins/vant'
import router from './router'
import store from './store'
import './assets/styles/index.scss'
import App from './App.vue'
import 'lib-flexible'
import {get, post} from './utils/http'
import API from 'api/index'

Vue.config.productionTip = false
Vue.use(vant)
Vue.use(VueCupUi)

Vue.prototype.get = get
Vue.prototype.post = post
Vue.prototype.API = API

// https://docs.apipost.cn/preview/3273b506fafea827/cbba0eea4f46d761?target_id=6d0fb7b4-9029-4a97-8d89-6a9f0d20151e#6d0fb7b4-9029-4a97-8d89-6a9f0d20151e

/**
 * 商品加入购物车
 */
Vue.prototype.addToCart = function(gid,skuid,number,group,successCallback,errorCallback){
  let that = this;
  if(typeof(group)=='undefined')
  {
	  group = "";
  }
  this.post(
    'index.php/wap/unionpay.Cart/add',
    {goods_id:gid, sku_id:skuid, number:number,group:group})
	.then(res => {
		successCallback(res.data);
	})
	.catch(err => {
		errorCallback && errorCallback(err.data)
	});
}
/**更新购物车中商品数量 */
Vue.prototype.updateNumberToCart = function(cartid,number,successCallback,errorCallback){
  let that = this;
  post(
    'index.php/wap/unionpay.Cart/updateNumber',
    {cart_id:cartid, number:number},
    successCallback,
    errorCallback
  )
}

/**检查用户是否已经登录 */
Vue.prototype.checkLogin = function(successCallback,errorCallback){
  this.post(
    'index.php/wap/unionpay.Ucenter/checkLogin', {})
	.then(res => {
		successCallback(res.data);
	})
	.catch(err => {
		errorCallback && errorCallback(err.data)
	});
},

upsdk.pluginReady(function () {
   upsdk.setTitleStyle({
	  // 可选，导航栏(含状态栏)背景色及透明度。16进制，前2位(8F)透明度，后六位(FFFFFF)颜色，仅对当前页有效，其余页还是申请配置的导航默认颜色
	  navBackgroundColor:'0xFFFFFFF',
	  appletStyle: 'black', //可选，black-黑色主题，white-白色主题
	  appletTitleBarVisible: '1',// 可选，标题栏是否显示。'0'不显示，'1'显示，默认显示y
	});
   // 前端API调用
   Vue.prototype.upsdk = upsdk
})

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
